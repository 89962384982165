<template>
  <page-container class="goods-detail-container">
    <van-icon class="back-icon" name="arrow-left" @click.stop="$router.back()"/>

    <!-- 商品图片 -->
    <div class="goods-img-swiper-wrap">
      <div class="swiper-container">
        <van-swipe
            style="height: 100%"
            @change="index => this.imgCurrent = ++index"
        >
          <van-swipe-item
              v-for="({ url }, index) of imageList"
              :key="index"
              @click="ImagePreview({
                images: imageList.map(({ url }) => url),
                startPosition: index,
              })"
          >
            <van-image
                :src="url"
                fill="cover"
            />
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">
              {{ imgCurrent }} / {{ imageList.length }}
            </div>
          </template>
        </van-swipe>
      </div>
    </div>

    <normal-goods-detail
        v-if="isNormalGoods && goodsInfo"
        ref="goodsDetail"
        :goods-info="goodsInfo"
        :level="level"
        :show="show"
        :addShopVal="addShopVal"
        :type="type"
        @next="next"
        @close="show=false"
        @specificationsShow="specificationsShow"
    />

    <group-goods-detail
        v-if="isGroupGoods && goodsInfo"
        ref="goodsDetail"
        :goods-info="goodsInfo"
    />

    <div class="goods-detail-bottom-bar">
      <div class="other-service-btn-container">
        <!--        <div @click="() => $router.push('/customerService')">-->
        <div @click="() => $router.push('/afterSale')">
          <van-icon name="service-o"/>
          售后
        </div>
        <div @click="() => $router.push('/home/shoppingCart')">
          <van-icon name="shopping-cart-o"/>
          购物车
        </div>
<!--        <div>-->
<!--          <van-icon name="share-o" @click="handleToShare"/>-->
<!--          分享-->
<!--        </div>-->
      </div>
     <div style="  display: flex;">
        <div class="buy-container">
        <van-button
            round
            :type="buyBtnType"
            style="width: 100%"
            @click="addShop"
        >
         加入购物车
        </van-button>
      </div>
      <div class="buy-container">
        <van-button
            round
            :type="buyBtnType"
            style="width: 100%"
            @click="handleToByBtnClick"
        >
          {{ buyBtnName }}
        </van-button>
      </div>
     </div>
    </div>

  </page-container>
</template>

<script>
import { GET_LOGIN_REFEREE_ID, GET_USER_ID } from '@/util/storage';
import { getNormalGoodsInfoByIdAndRefereeId } from '@/api/normalGoods';
import { getGroupGoodsInfoByIdAndRefereeId } from '@/api/groupGoods';
import { getUserInfo } from '@/api/user';
import { ImagePreview, Toast } from 'vant';
import NormalGoodsDetail from '@/views/goodsDetail/normalGoodsDetail';
import GroupGoodsDetail from '@/views/goodsDetail/groupGoodsDetail';
import { VUE_APP_API_URL, VUE_APP_SHARE_URL } from '@/config';
import { wxShare } from '@/util/wxShare';
import { signContract2_1, signContract6_1 } from '@/components/SignContract';
import {addGoodsCart} from '@/api/shoppingCart'

export default {
  name: 'goodsDetail',
  components: { GroupGoodsDetail, NormalGoodsDetail },
  data() {
    return {
      normalGoodsId: '',
      groupGoodsId: '',
      imgCurrent: 1,
      specificationsObj:{},

      userInfo: {},
      goodsInfo: null,
      show:false,
      addShopVal:false,
      type:""
    };
  },
  computed: {
    level() {
      return this.userInfo.level || '';
    },
    // 商品图片
    imageList() {
      let { imageList, thumbImageUrl } = this.goodsInfo || {};
      let image = Array.isArray(imageList) ? imageList : [];
      let first = thumbImageUrl && image.every(e => e.url !== thumbImageUrl) ? [{ url: thumbImageUrl }] : [];
      return [...first, ...image];
    },
    // 是否普通商品
    isNormalGoods() {
      return !!this.normalGoodsId;
    },
    // 是否夺标商品
    isGroupGoods() {
      return !!this.groupGoodsId;
    },
    // 购买按钮名称
    buyBtnName() {
      let { isNormalGoods, isGroupGoods } = this;
      let btnName = '立刻购买';
      if (isNormalGoods)
        btnName = '立刻购买';
      else if (isGroupGoods)
        btnName = '立刻拼购';
      return btnName;
    },
    buyBtnType() {
      let { isNormalGoods, isGroupGoods } = this;
      let btnType = 'primary';
      if (isNormalGoods)
        btnType = 'primary';
      else if (isGroupGoods)
        btnType = 'warning';
      return btnType;
    },
  },
  async created() {
    let { normalGoodsId, groupGoodsId } = this.$route.query || {};
    this.normalGoodsId = normalGoodsId;
    this.groupGoodsId = groupGoodsId;

    let service = null;
    let goodsId = '';
    if (this.isNormalGoods) {
      service = getNormalGoodsInfoByIdAndRefereeId;
      goodsId = normalGoodsId;
    }
    if (this.isGroupGoods) {
      service = getGroupGoodsInfoByIdAndRefereeId;
      goodsId = groupGoodsId;
    }

    let pms1 = getUserInfo().then(res => {
      this.userInfo = res || {};
    });

    if (typeof service === 'function') {
      let pms2 = service({
        id: goodsId,
        refereeId: GET_LOGIN_REFEREE_ID(),
      }).then(res => {
        this.goodsInfo = res || {};
        if (!res)
          this.$router.back();
        else {
          // 商品详情富文本图片预览
          this.$nextTick(() => {
            let $goodsDetailContainer = this.$refs.goodsDetail.$el.querySelectorAll('.goods-detail-container')[0];
            let $images = $goodsDetailContainer.querySelectorAll('img');
            let images = [...$images].map(e => e.src);
            $images.forEach(($img, index) => {
              $img.addEventListener('click', () => {
                ImagePreview({
                  images,
                  startPosition: index,
                });
              });
            });
          });
        }
      });

      // Promise.all([pms1, pms2]).then(this.setWxShare);

      await signContract2_1(); //合同2-1签署
      await signContract6_1() //合同6-1签署
    }
  },
  methods: {
    ImagePreview,
    // 判断并获取商品类型对应的id名及值
    getFormatGoodsObj() {
      let { normalGoodsId, groupGoodsId } = this.$route.query || {};
      let goodsIdKey = '';
      let goodsId = '';
      if (normalGoodsId) {
        goodsIdKey = 'normalGoodsId';
        goodsId = normalGoodsId;
      }
      if (groupGoodsId) {
        goodsIdKey = 'groupGoodsId';
        goodsId = groupGoodsId;
      }

      return {
        key: goodsIdKey,
        value: goodsId,
      };
    },
    setWxShare() {
      let shareUrl = VUE_APP_SHARE_URL + '?';

      let { level, isNormalGoods, isGroupGoods } = this;
      let imgUrlBaseUrl = VUE_APP_API_URL;
      let refereeId = GET_LOGIN_REFEREE_ID();
      let userId = GET_USER_ID();
      // normalGoodsId, groupGoodsId

      // 商品id参数设置
      let { key: goodsKey, value: goodsId } = this.getFormatGoodsObj();

      let { invitationCode } = this.userInfo; // 登录用户的邀请码
      let shareInvitationCode = `invitationCode=${invitationCode}`; // 分享邀请码
      shareUrl += `${shareInvitationCode}&`;

      let goodsIdParam = `${goodsKey}=${goodsId}`; // 商品id
      let refereeIdParam = `refereeId=${refereeId}`; // 商品店铺id
      let betweenIdParam = `betweenId=${userId}`; // 登录用户id
      let goodsDetailRedirectParam = `state=/goodsDetail?${goodsIdParam}`; // 分享路由页面地址?参数=

      // 分享地址拼接
      // if (+level === 1) {
      shareUrl += `${goodsDetailRedirectParam}`;
      // } else if (+level === 2) {
      //   shareUrl += `${refereeIdParam}&${betweenIdParam}&${goodsDetailRedirectParam}`;
      // } else {
      //   shareUrl += `${refereeIdParam}&${betweenIdParam}&${goodsDetailRedirectParam}`;
      // }

      let { name, subName, thumbImageUrl } = this.goodsInfo;
      wxShare({
        title: name || '',
        desc: subName || '',
        imgUrl: imgUrlBaseUrl + thumbImageUrl,
        link: shareUrl || '',
        success: () => {
          Toast('分享成功');
        }
      });
    },
    addShop(){
      if(this.goodsInfo.goodsSpecGroupList.length!==0){
        this.addShopVal=true
        this.type='shop'
        this.specificationsShow()
      }else{
         addGoodsCart({
               count:1,
               goodsId:this.goodsInfo.id
             }).then(res=>{
               if(res){
                 Toast('加入购物车成功!')

               }else{
                  Toast('加入购物车失败!')
               }
              this.show=false
             })
      }
    },
    handleToByBtnClick() {
       if(this.goodsInfo.goodsSpecGroupList.length!==0){
         if(!this.specificationsObj.specIdList||(this.specificationsObj.specIdList.length!==this.goodsInfo.goodsSpecGroupList.length)) {
           this.type="payMent"
         this.specificationsShow()
         return
         }
      }
      this.$store.commit('payment/setRefreshing', true);

      let { key, value } = this.getFormatGoodsObj();
      this.$router.push({
        path: '/payment',
        query: {
          [key]: value,
          specificationsObj:JSON.stringify(this.specificationsObj)
        }
      });
    },
    handleToShare() {
      let shareUrl = VUE_APP_SHARE_URL + '?';
      // 商品id参数设置
      let { key: goodsKey, value: goodsId } = this.getFormatGoodsObj();
      let { invitationCode } = this.userInfo; // 登录用户的邀请码
      let shareInvitationCode = `invitationCode=${invitationCode}`; // 分享邀请码
      shareUrl += `${shareInvitationCode}&`;
      let goodsIdParam = `${goodsKey}=${goodsId}`; // 商品id
      let goodsDetailRedirectParam = `state=/goodsDetail?${goodsIdParam}`; // 分享路由页面地址?参数=
      shareUrl += `${goodsDetailRedirectParam}`;

      this.$copyText(shareUrl);
      ImagePreview({
        images: ['goodsDetail/shareText.png'],
        showIndex: false,
        closeable: true,
        maxZoom: 1,
        minZoom: 1,
      })
    },
    next(obj){
      this.show=false
      this.specificationsObj=obj
      this.handleToByBtnClick()
    },
    specificationsShow(){
      this.show=true
    }
  },
};
</script>

<style lang="less" scoped>
.back-icon {
  position: fixed;
  z-index: 2;
  top: 12px;
  left: 12px;
  box-sizing: border-box;
  padding: 6px;
  font-size: 24px;
  color: #000;
  background-color: rgba(255, 255, 255, .8);
  border-radius: 100%;
  border: 1px solid #dedede;
}

.goods-detail-container {
  padding: 0 0 54px 0;

  .goods-img-swiper-wrap {
    position: relative;
    width: 100%;
    padding-top: 100%;

    .swiper-container {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      .custom-indicator {
        position: absolute;
        z-index: 20;
        right: 0;
        bottom: 15px;
        height: 20px;
        padding: 0 10px;
        background: #00000080;
        border-radius: 10px 0 0 10px;
        font-size: 10px;
        color: #fff;
        line-height: 20px;
      }

      .van-swipe-item {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .goods-detail-bottom-bar {
    position: fixed;
    z-index: 2;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    width: 100%;
    height: 54px;
    background-color: #fff;

    .other-service-btn-container {
      display: flex;
      height: 100%;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 47px;
        color: #666;

        & > .van-icon {
          font-size: 21px;
        }
      }
    }

    .buy-container {
      width: 110px;
      font-size: 14px;
      font-weight: bolder;
      margin-left: 10px;
    }
  }
}
</style>

