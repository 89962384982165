<!-- 夺标商品详情 -->
<template>
  <div class="normal-goods-container">

    <div class="price-count-down-container">
      <div class="price"><span>{{ goodsInfo.goodsPrice }}</span></div>
      <div class="count-down-container">
        <div class="count-tip-text">距离结束仅剩</div>
        <van-count-down
            ref="countDown"
            v-if="count"
            :time="count"
            @finish="() => {this.$router.back()}"
        >
          <template #default="timeData">
            <span class="block">{{ padZero(timeData.hours) }}</span>
            <span class="colon">:</span>
            <span class="block">{{ padZero(timeData.minutes) }}</span>
            <span class="colon">:</span>
            <span class="block">{{ padZero(timeData.seconds) }}</span>
          </template>
        </van-count-down>
      </div>
    </div>

    <div class="goods-info-card">
      <div class="goods-price-container">
        <div>
          <span class="display-price"><span>{{ goodsInfo.primePrice }}</span></span>
        </div>
      </div>

      <div class="goods-name-container">
        <p>{{ goodsInfo.name }}</p>
        <div>{{ goodsInfo.subName }}</div>
      </div>

      <div class="stock-info-container">
        <div>
          <div
              class="postage-desc"
              :class="{'has-postage': +goodsInfo.attribute === 1}"
          >
            <van-icon
                :name="+goodsInfo.attribute !== 1 ? 'passed' : 'close'"
            />
            {{ postageDesc }}
          </div>
        </div>
        <div v-if="goodsInfo.groupStock">
          库存：{{ goodsInfo.groupStock.count }}
        </div>
      </div>
    </div>


    <div class="goods-detail-container" v-html="goodsInfo.goodsDetail">
    </div>
  </div>
</template>

<script>
export default {
  name: 'groupGoodsDetail',
  props: {
    level: {
      default: () => '',
    },
    goodsInfo: {
      default: () => ({}),
    },
  },
  data() {
    return {
      count: 0, //倒计时，单位毫秒
    };
  },
  mounted() {
    let limitMinutes = this.goodsInfo.limitMinites;
    let groupRecord = this.goodsInfo.groupRecord || {};
    let expireTime = groupRecord.expireTime;

    if (expireTime) {
      this.count = new Date(expireTime).getTime() - new Date().getTime();
    } else if (limitMinutes) {
      this.count = limitMinutes * 60 * 1000;
    } else {
      this.count = 0;
    }
  },
  computed: {
    isLevel1() {
      return +this.level === 1;
    },
    displayPrice() {
      let { goodsInfo, isLevel1 } = this;
      let price = isLevel1 ? goodsInfo.goodsPrice : goodsInfo.memberPrice;
      return this.formatPrice(price);
    },
    postageDesc() {
      let { goodsInfo } = this;
      return {
        '1': '不包邮',
        '2': '包邮',
        '3': '部分地区包邮',
      }[goodsInfo.attribute] || '';
    },
  },
  created() {
  },
  methods: {
    formatPrice(price) {
      price = price || 0;
      price = parseFloat(price).toFixed(3);
      return price.substring(0, price.length - 1);
    },
    padZero(num, targetLength = 2) {
      let str = num + '';
      while (str.length < targetLength) {
        str = '0' + str;
      }
      return str;
    },
  },
};
</script>

<style lang="less" scoped>
.normal-goods-container {

  .price-count-down-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    background-color: @warning-color;

    .price {
      display: flex;
      align-items: flex-end;
      line-height: 1;
      font-size: 26px;
      font-weight: bolder;
      color: #fff;

      &:before {
        content: '¥';
        margin-right: 4px;
        line-height: 16px;
        font-size: 15px;
        font-weight: normal;
      }
    }

    .count-down-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      color: #fff;

      .count-tip-text {
        margin-bottom: 4px;
        font-size: 14px;
        letter-spacing: 1px;
      }

      .colon {
        display: inline-block;
        margin: 0 4px;
        color: #fff;
      }
      .block {
        display: inline-block;
        width: 21px;
        height: 21px;
        line-height: 21px;
        color: @warning-color;
        font-size: 14px;
        text-align: center;
        background-color: #fff;
        border-radius: 4px;
      }
    }

  }

  .goods-info-card {
    margin: 10px;
    padding: 10px;
    border-radius: 15px;
    background-color: #fff;
  }

  .goods-price-container {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
      line-height: 1;
      font-size: 13px;
      color: #999;
    }

    .level-price-img {
      margin-left: 5px;
      width: 25px;
      height: 25px;
    }

    .display-price {
      display: flex;
      align-items: flex-end;
      line-height: 1;
      font-size: 20px;
      color: #e93b3d;

      &:before {
        content: '¥';
        margin-right: 4px;
        line-height: 16px;
        font-size: 15px;
        font-weight: normal;
      }
    }
  }

  .goods-name-container {
    margin-top: 8px;
    font-size: 16px;

    & > div {
      margin-top: 6px;
      font-size: 14px;
      color: #666;
    }
  }

  .postage-desc {
    font-size: 14px;
    color: @primary-color;

    &.has-postage {
      color: red;
    }

    .van-icon {
      font-size: 14px;
      font-weight: bolder;

      &.van-icon-passed {

      }
    }
  }

  .stock-info-container {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px;
    color: #666;
  }

  /deep/ .goods-detail-container {
    padding: 10px 0;
    background-color: #fff;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}
</style>
