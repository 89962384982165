<!-- 普通商品详情 分类: 1级别 其它级别 -->
<template>
  <div class="normal-goods-container">
    <div class="goods-info-card">
      <div class="goods-price-container">
        <div>
          <span class="display-price"
            ><span>{{ displayPrice }}</span></span
          >
          <van-image
            class="level-price-img"
            fit="contain"
            :src="
              goodsInfo.memberPrice
                ? 'goodsDetail/memberPrice.png'
                : 'goodsDetail/favorablePrice.png'
            "
          />
        </div>

        <div v-if="goodsInfo.memberPrice">
          <span>优惠价:</span
          ><span class="other-price">{{ goodsInfo.goodsPrice }}</span>
        </div>

        <div style="text-decoration: line-through">
          原价: <span class="other-price">{{ goodsInfo.primePrice }}</span>
        </div>
      </div>
      <div class="goods-name-container">
        <p>{{ goodsInfo.name }}</p>
        <div>{{ goodsInfo.subName }}</div>
      </div>
    </div>

    <div class="goods-info-card">
      <!-- 是否包邮 -->
      <div
        class="postage-desc"
        :class="{ 'has-postage': +goodsInfo.attribute === 1 }"
      >
        <van-icon :name="+goodsInfo.attribute !== 1 ? 'passed' : 'close'" />
        {{ postageDesc }}
      </div>

      <div class="stock-info-container">
        <div>下单2~4天可发货</div>
        <div v-if="goodsInfo.goodsStock">
          库存：{{ goodsInfo.goodsStock.count }}
        </div>
      </div>
    </div>
    <div v-if="!!tagTitle" class="specifications goods-info-card" @click="specificationsBtn">
      <div class="flex">
        <div style="color: #6f6d6d; margin-right: 10px">选择</div>
        <div>{{tagTitle}}</div>
      </div>
      <div><van-icon name="arrow" /></div>
    </div>

    <div class="goods-detail-container" v-html="goodsInfo.goodsDetail"></div>
    <!-- <van-sku
      v-model="specificationsShow"
      :sku="sku"
      :goods="goods"
      :message-config="messageConfig"
      :show-add-cart-btn="false"
      @buy-clicked="onBuyClicked"
      @add-cart="onAddCartClicked"
    >
    </van-sku> -->
    <van-popup
      v-model="show"
      round
      position="bottom"
      :close-on-click-overlay="false"
      :style="{ height: '70%' }"
    >
      <div class="popupSty">
        <div class="title">
          <div>
            <van-image width="92px" height="92px" fit="contain" :src="imgURL" />
            <!-- <van-image
              width="10rem"
              height="10rem"
              fit="contain"
              src="https://img01.yzcdn.cn/vant/cat.jpeg"
            /> -->
          </div>
          <div style="line-height: 24px">
            <span class="money">{{goodsInfo.name}}</span>
            <div>库存: {{goodsInfo.goodsStock.count}}</div>
            <div>请选择规格</div>
          </div>
          <div class="icon-close" @click="close"><van-icon name="cross" /></div>
        </div>
        <div
          class="popup-type"
          v-for="(item, index) in goodsSpecGroupList"
          :key="index"
        >
          <div>{{ item.specGroupName }}</div>
          <div class="specValueSty">
            <div
              v-for="(ele, index1) in item.goodsSpecList"
              :key="index1"
              @click="ruleSelect(index, index1, item, ele)"
              :class="[States[index] == index1 ? 'select' : '']"
            >
              {{ ele.specValue }}
            </div>
          </div>
        </div>
        <div class="countSty">
            <van-cell title="数量" >
               <van-stepper v-model="count" min="0" :max="goodsInfo.goodsStock.count "/>
            </van-cell>

          
        </div>
        <div class="nextBtn" @click="next">
          <!-- <van-button type="danger" block round>{{addShopCard&&!!tagTitle ?'加入购物车':'下一步'}}</van-button> -->
          <van-button type="danger" block round>{{addShopCard&&type==='shop' ?'加入购物车':'下一步'}}</van-button>
          
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant';
import {addGoodsCart} from '@/api/shoppingCart'
export default {
  name: "normalGoodsDetail",
  props: {
    level: {
      default: () => "",
    },
    goodsInfo: {
      default: () => ({}),
    },
    show:{
      default: () =>false,
    },
    addShopVal:{
      default: () =>false,
    },
    type:{
      default: () => "",
    }
  },
  data() {
    return {
      States: {},
      specificationsShow: false,
      activeIndex: null,
      actived: null,
      goodsSpecGroup: {},
      imgURL: "",
      arr: [],
      tagTitle:'',
      specIdList: [],
      count:0,
      goodsSpecGroupList:[],
      thumbImageUrl:false,
    };
  },
  computed: {
    isLevel1() {
      return +this.level === 1;
    },
    displayPrice() {
      let { goodsInfo } = this;
      let price = goodsInfo.memberPrice || goodsInfo.goodsPrice;
      return this.formatPrice(price);
    },
    postageDesc() {
      let { goodsInfo } = this;
      return (
        {
          1: "不包邮",
          2: "包邮",
          3: "部分地区包邮",
        }[goodsInfo.attribute] || ""
      );
    },
    addShopCard(){
   return this.addShopVal
    }
  },
  
  created() {
    if(this.goodsInfo.goodsSpecGroupList.length!==0){
      this.goodsSpecGroupList = this.goodsInfo.goodsSpecGroupList;
      if(!this.goodsInfo.goodsSpecGroupList[0].goodsSpecList[0].specImageUrl){
        this.thumbImageUrl=true
        this.imgURL =this.goodsInfo.thumbImageUrl
      }else{
         this.thumbImageUrl=false
        this.imgURL =this.goodsInfo.goodsSpecGroupList[0].goodsSpecList[0].specImageUrl;
      }
     
    }
    this.count=this.goodsInfo.goodsStock.count==0?0:1
    
    this.init()
    
  },
  methods: {
    init(){
       if(this.goodsInfo.goodsSpecGroupList.length!==0){
            let index = 0;
            var obj = {};
            var States = {};
             for (obj in this.goodsSpecGroupList) {
              States[index] = 999;
               index++;
             }
            this.States = States;
            // 0:0 表示specs[0]第一个【高级】高亮显示；1:0 表示specs[1]第一个【高级业主】高亮显示
            console.log("默认高亮显示的项：", this.States); // 0:0 ; 1:0
            let titleArr=[]
            this.goodsSpecGroupList.forEach((item,index) => {
              titleArr[index]=item.specGroupName
            });
            this.tagTitle=titleArr.join(" ")
      }
    },
    formatPrice(price) {
      price = price || 0;
      price = parseFloat(price).toFixed(3);
      return price.substring(0, price.length - 1);
    },
    specificationsBtn() {
      this.$emit('specificationsShow')
    },
    ruleSelect(index, index1, item, ele) {
       if (index === 0) {
        if(!ele.specImageUrl){
          this.thumbImageUrl=true
          this.imgURL =this.goodsInfo.thumbImageUrl
        }else{
          this.thumbImageUrl=false
          this.imgURL = ele.specImageUrl;
        }
        
           
      }
      this.States[index] = index1;
      this.arr[index] = ele.specValue;
      this.tagTitle= this.arr.join(" ")
      this.specIdList[index] = ele.id;
    },
    next() {
      if(this.addShopCard&&this.type==='shop' ){
          if(this.goodsInfo.closeRule===2 ||this.goodsInfo.closeRule===3) {
            return Toast('此商品需单独购买!')
         }
        // 加入购物车
        if(this.specIdList.length===this.goodsInfo.goodsSpecGroupList.length){
            addGoodsCart({
               specTitle:this.tagTitle,
               specIdList: this.specIdList,
               count:this.count,
               goodsId:this.goodsInfo.id
             }).then(res=>{
               if(res){
                 Toast('加入购物车成功!')

               }else{
                  Toast('加入购物车失败!')
               }
              this.close()
             })

        }
        
      }else{
        // 下一步  提交订单
        let obj = {
        tagIcon: this.imgURL,
        tagTitle:this.tagTitle,
        specIdList: this.specIdList,
        count:this.count
      };
      this.$emit('next',obj)

      }
      
    },
    close(){
       this.init()
       this.specIdList=[]
       this.$emit('close')

    },
    onBuyClicked() {},
    onAddCartClicked() {},
  },
};
</script>

<style lang="less" scoped>
.normal-goods-container {
  .goods-info-card {
    margin: 10px;
    padding: 10px;
    border-radius: 15px;
    background-color: #fff;
  }
  .specifications {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .flex {
      display: flex;
    }
  }

  .goods-price-container {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
      line-height: 1;
      font-size: 13px;
      color: #999;
    }

    .level-price-img {
      margin-left: 5px;
      width: 25px;
      height: 25px;
    }

    .display-price {
      display: flex;
      align-items: flex-end;
      line-height: 1;
      font-size: 24px;
      font-weight: bolder;
      color: #e93b3d;

      &:before {
        content: "¥";
        margin-right: 4px;
        line-height: 20px;
        font-size: 15px;
        font-weight: normal;
      }
    }

    .other-price {
      display: flex;
      align-items: flex-end;
      line-height: 1;
      font-size: 13px;
      color: #999;

      &:before {
        content: "¥ ";
        margin-right: 3px;
        line-height: 1;
        font-size: 13px;
        font-weight: normal;
      }
    }
  }

  .goods-name-container {
    margin-top: 8px;
    font-size: 16px;

    & > div {
      margin-top: 6px;
      font-size: 14px;
      color: #666;
    }
  }

  .postage-desc {
    font-size: 14px;
    color: @primary-color;

    &.has-postage {
      color: red;
    }

    .van-icon {
      font-size: 14px;
      font-weight: bolder;

      &.van-icon-passed {
      }
    }
  }

  .stock-info-container {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 12px;
    color: #666;
  }

  /deep/ .goods-detail-container {
    padding: 10px 0;
    background-color: #fff;

    img {
      max-width: 100%;
      height: auto;
    }
  }
  .popupSty {
    padding: 20px 10px 0 10px;
      .icon-close{
    position: absolute;
    top: 18px;
    right: 15px;
    font-size: 22px;

  }
    .title {
      display: flex;
      align-items: end;
      > div {
        margin-right: 10px;
        color: rgb(114, 113, 113);
      }
      .money {
        color: red;
        font-weight: 700;
      }
    }
    .popup-type {
      padding: 10px 0;
    }
    .specValueSty {
      display: flex;
      margin-top: 10px;
      flex-wrap: wrap;
      > div {
        background-color: #f7f8fa;
        margin-right: 10px;
        padding: 7px 6px;
        min-width: 60px;
        border-radius: 6px;
        margin-bottom: 10px;
      
      }
    }
    .select {
      color: red !important;
      border: 1px solid red;
    }
    .nextBtn {
      margin-top: 30px;
    }
  }
 
}
 ::v-deep .countSty{
   .van-cell{
     padding: 10px 0 !important;
   }
    
  }

</style>

