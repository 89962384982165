var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',{staticClass:"goods-detail-container"},[_c('van-icon',{staticClass:"back-icon",attrs:{"name":"arrow-left"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.back()}}}),_c('div',{staticClass:"goods-img-swiper-wrap"},[_c('div',{staticClass:"swiper-container"},[_c('van-swipe',{staticStyle:{"height":"100%"},on:{"change":function (index) { return this$1.imgCurrent = ++index; }},scopedSlots:_vm._u([{key:"indicator",fn:function(){return [_c('div',{staticClass:"custom-indicator"},[_vm._v(" "+_vm._s(_vm.imgCurrent)+" / "+_vm._s(_vm.imageList.length)+" ")])]},proxy:true}])},_vm._l((_vm.imageList),function(ref,index){
var url = ref.url;
return _c('van-swipe-item',{key:index,on:{"click":function($event){_vm.ImagePreview({
                images: _vm.imageList.map(function (ref) {
                              var url = ref.url;

                              return url;
}),
                startPosition: index,
              })}}},[_c('van-image',{attrs:{"src":url,"fill":"cover"}})],1)}),1)],1)]),(_vm.isNormalGoods && _vm.goodsInfo)?_c('normal-goods-detail',{ref:"goodsDetail",attrs:{"goods-info":_vm.goodsInfo,"level":_vm.level,"show":_vm.show,"addShopVal":_vm.addShopVal,"type":_vm.type},on:{"next":_vm.next,"close":function($event){_vm.show=false},"specificationsShow":_vm.specificationsShow}}):_vm._e(),(_vm.isGroupGoods && _vm.goodsInfo)?_c('group-goods-detail',{ref:"goodsDetail",attrs:{"goods-info":_vm.goodsInfo}}):_vm._e(),_c('div',{staticClass:"goods-detail-bottom-bar"},[_c('div',{staticClass:"other-service-btn-container"},[_c('div',{on:{"click":function () { return _vm.$router.push('/afterSale'); }}},[_c('van-icon',{attrs:{"name":"service-o"}}),_vm._v(" 售后 ")],1),_c('div',{on:{"click":function () { return _vm.$router.push('/home/shoppingCart'); }}},[_c('van-icon',{attrs:{"name":"shopping-cart-o"}}),_vm._v(" 购物车 ")],1)]),_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"buy-container"},[_c('van-button',{staticStyle:{"width":"100%"},attrs:{"round":"","type":_vm.buyBtnType},on:{"click":_vm.addShop}},[_vm._v(" 加入购物车 ")])],1),_c('div',{staticClass:"buy-container"},[_c('van-button',{staticStyle:{"width":"100%"},attrs:{"round":"","type":_vm.buyBtnType},on:{"click":_vm.handleToByBtnClick}},[_vm._v(" "+_vm._s(_vm.buyBtnName)+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }